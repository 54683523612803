import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 100%;
`;

const Button = styled.button`
  background: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  &:hover:enabled {
    background: #0056b3;
  }
`;

const ScheduleModal = ({ isOpen, onClose, onSchedule, broadcastName }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    if (isOpen) {
      setSelectedDate(null);
    }
  }, [isOpen]);

  const handleSchedule = () => {
    if (selectedDate) {
      const confirmation = window.confirm(`Do you want to schedule ${broadcastName} to be sent on ${selectedDate.toLocaleString('en-US', { timeZone: 'Asia/Singapore' })}?`);
      if (confirmation) {
        onSchedule(selectedDate);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <ModalBackground>
      <ModalContainer>
        <h2>Schedule Broadcast</h2>
        <DatePicker
          selected={selectedDate}
          onChange={setSelectedDate}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MMMM d, yyyy h:mm aa"
          minDate={new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Singapore' }))}
          placeholderText="Select a date and time"
        />
        <div style={{ marginTop: '20px' }}>
          <Button
            onClick={handleSchedule}
            disabled={!selectedDate}
          >
            Schedule
          </Button>
          <Button onClick={onClose} style={{ marginLeft: '10px', background: '#dc3545' }}>
            Cancel
          </Button>
        </div>
      </ModalContainer>
    </ModalBackground>
  );
};

export default ScheduleModal;
