const API_URL = 'https://topgreatnews.com/scheduler/api';

const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
};

export const getBroadcasts = async () => {
  try {
    const response = await fetch(`${API_URL}/broadcasts`, { headers: getAuthHeaders() });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data.broadcasts;
  } catch (error) {
    console.error('Failed to fetch broadcasts:', error);
    throw error;
  }
};

export const getScheduledBroadcasts = async () => {
  try {
    const response = await fetch(`${API_URL}/scheduled-broadcasts`, { headers: getAuthHeaders() });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch scheduled broadcasts:', error);
    throw error;
  }
};

export const deleteScheduledBroadcasts = async (ids) => {
  try {
    const response = await fetch(`${API_URL}/scheduled-broadcasts`, {
      method: 'DELETE',
      headers: getAuthHeaders(),
      body: JSON.stringify({ ids }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    console.error('Failed to delete scheduled broadcasts:', error);
    throw error;
  }
};

export const scheduleBroadcast = async (broadcastId, scheduleTime) => {
  try {
    console.log(`Scheduling broadcast with ID ${broadcastId} at ${scheduleTime}`);
    const response = await fetch(`${API_URL}/schedule`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify({ broadcastId, scheduleTime }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    console.error('Failed to schedule broadcast:', error);
    throw error;
  }
};

export const updateScheduledBroadcast = async (id, scheduleTime) => {
  try {
    console.log(`Updating scheduled broadcast with ID ${id} to new time ${scheduleTime}`);
    const response = await fetch(`${API_URL}/scheduled-broadcasts/${id}`, {
      method: 'PUT',
      headers: getAuthHeaders(),
      body: JSON.stringify({ scheduleTime }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  } catch (error) {
    console.error('Failed to update scheduled broadcast:', error);
    throw error;
  }
};
