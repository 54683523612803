import React, { useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import VerticalMenu from './components/VerticalMenu';
import MainContent from './components/MainContent';
import Login from './components/Login'; // Ensure the import path is correct

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
`;

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [selectedContent, setSelectedContent] = useState('broadcasts');

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/scheduler/login"
          element={token ? <Navigate to="/scheduler" /> : <Login setToken={saveToken} />}
        />
        <Route
          path="/scheduler"
          element={
            token ? (
              <AppContainer>
                <VerticalMenu onSelect={setSelectedContent} />
                <MainContent content={selectedContent} />
              </AppContainer>
            ) : (
              <Navigate to="/scheduler/login" />
            )
          }
        />
        {/* Add a fallback route to handle unmatched paths */}
        <Route path="*" element={<Navigate to="/scheduler" />} />
      </Routes>
    </Router>
  );
};

export default App;
