import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }

  .pagination li {
    display: inline;
    margin: 0 5px;
  }

  .pagination li a {
    text-decoration: none;
    color: #007bff;
    cursor: pointer;
  }

  .pagination li a:hover {
    text-decoration: underline;
  }

  .pagination .active a {
    font-weight: bold;
    color: #0056b3;
  }
`;
