// src/components/VerticalMenu.js
import React from 'react';
import styled from 'styled-components';

const MenuContainer = styled.div`
  max-height: 100%;
  max-width: 200px;
  width: 100%;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const MenuItem = styled.div`
  margin: 10px 0;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const VerticalMenu = ({ onSelect }) => {
  return (
    <MenuContainer>
      <MenuItem onClick={() => onSelect('broadcasts')}>Broadcasts</MenuItem>
      <MenuItem onClick={() => onSelect('scheduled-broadcasts')}>Scheduled Broadcasts</MenuItem>
    </MenuContainer>
  );
};

export default VerticalMenu;
