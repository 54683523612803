import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getBroadcasts, getScheduledBroadcasts, deleteScheduledBroadcasts, scheduleBroadcast, updateScheduledBroadcast } from '../api';
import ScheduleModal from './ScheduleModal';
import { format, toZonedTime, utcToZonedTime } from 'date-fns-tz';
import ReactPaginate from 'react-paginate';
import { PaginationContainer } from './PaginationStyles';

const ContentContainer = styled.div`
  flex: 1;
  padding: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  th {
    background-color: #f2f2f2;
  }
`;

const BulkActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const BulkActionDropdown = styled.select`
  padding: 10px;
  font-size: 16px;
`;

const Button = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
`;

const ITEMS_PER_PAGE = 10;

const MainContent = ({ content }) => {
  const [broadcasts, setBroadcasts] = useState([]);
  const [scheduledBroadcasts, setScheduledBroadcasts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedBroadcast, setSelectedBroadcast] = useState(null);
  const [selectedBroadcasts, setSelectedBroadcasts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bulkAction, setBulkAction] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBroadcasts = async () => {
      try {
        const data = await getBroadcasts();
        setBroadcasts(data);
      } catch (err) {
        console.error('Error fetching broadcasts:', err);
        setError('Failed to fetch broadcasts.');
      }
    };

    const fetchScheduledBroadcasts = async () => {
      try {
        const data = await getScheduledBroadcasts();
        setScheduledBroadcasts(data);
      } catch (err) {
        console.error('Error fetching scheduled broadcasts:', err);
        setError('Failed to fetch scheduled broadcasts.');
      }
    };

    if (content === 'broadcasts') {
      fetchBroadcasts();
    } else if (content === 'scheduled-broadcasts') {
      fetchScheduledBroadcasts();
    }
  }, [content]);

  const handleSelect = (broadcast) => {
    setSelectedBroadcast(broadcast);
    setIsModalOpen(true);
  };

  const handleEdit = (broadcast) => {
    setSelectedBroadcast(broadcast);
    setEditMode(true);
    setIsModalOpen(true);
  };

  const handleBulkSchedule = () => {
    if (selectedBroadcasts.length > 0) {
      setIsModalOpen(true);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedBroadcasts.length > 0) {
      try {
        await deleteScheduledBroadcasts(selectedBroadcasts);
        setSelectedBroadcasts([]);
        const data = await getScheduledBroadcasts();
        setScheduledBroadcasts(data);
      } catch (err) {
        console.error('Error deleting scheduled broadcasts:', err);
        setError('Failed to delete scheduled broadcasts.');
      }
    }
  };

  const handleSchedule = async (scheduleTime) => {
    const timeZone = 'Asia/Singapore';
    const scheduleTimeUtc = format(toZonedTime(scheduleTime, timeZone), "yyyy-MM-dd HH:mm:ss", { timeZone });

    try {
      if (bulkAction === 'schedule' && selectedBroadcasts.length > 0) {
        await Promise.all(
          selectedBroadcasts.map((broadcastId) =>
            scheduleBroadcast(broadcastId, scheduleTimeUtc)
          )
        );
        setSelectedBroadcasts([]);
      } else if (editMode) {
        await updateScheduledBroadcast(selectedBroadcast.id, scheduleTimeUtc);
        setEditMode(false);
      } else if (selectedBroadcast) {
        await scheduleBroadcast(selectedBroadcast.id, scheduleTimeUtc);
      }

      setIsModalOpen(false);
      setSelectedBroadcast(null);
      const data = await getScheduledBroadcasts();
      setScheduledBroadcasts(data);
    } catch (err) {
      console.error('Error scheduling broadcast:', err);
      setError('Failed to schedule broadcast.');
    }
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const offset = currentPage * ITEMS_PER_PAGE;
  const paginatedBroadcasts = content === 'broadcasts'
    ? broadcasts.slice(offset, offset + ITEMS_PER_PAGE)
    : scheduledBroadcasts.slice(offset, offset + ITEMS_PER_PAGE);

  const handleCheckboxChange = (broadcastId) => {
    setSelectedBroadcasts((prevSelected) =>
      prevSelected.includes(broadcastId)
        ? prevSelected.filter((id) => id !== broadcastId)
        : [...prevSelected, broadcastId]
    );
  };

  return (
    <ContentContainer>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {content === 'broadcasts' && (
        <>
          <BulkActionContainer>
            <BulkActionDropdown value={bulkAction} onChange={(e) => setBulkAction(e.target.value)}>
              <option value="">Bulk Action</option>
              <option value="delete">Delete</option>
              <option value="schedule">Schedule</option>
            </BulkActionDropdown>
            {bulkAction === "schedule" && (
              <Button onClick={handleBulkSchedule} disabled={selectedBroadcasts.length === 0}>Apply</Button>
            )}
          </BulkActionContainer>
          <Table>
            <thead>
              <tr>
                <th>Select</th>
                <th>ID</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedBroadcasts.map((broadcast) => (
                <tr key={broadcast.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedBroadcasts.includes(broadcast.id)}
                      onChange={() => handleCheckboxChange(broadcast.id)}
                    />
                  </td>
                  <td>{broadcast.id}</td>
                  <td>{broadcast.name}</td>
                  <td>
                    <button onClick={() => handleSelect(broadcast)}>Schedule</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationContainer>
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={Math.ceil(broadcasts.length / ITEMS_PER_PAGE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </PaginationContainer>
        </>
      )}
      {content === 'scheduled-broadcasts' && (
        <>
          <BulkActionContainer>
            <BulkActionDropdown value={bulkAction} onChange={(e) => setBulkAction(e.target.value)}>
              <option value="">Bulk Action</option>
              <option value="delete">Delete</option>
            </BulkActionDropdown>
            {bulkAction === "delete" && (
              <Button onClick={handleBulkDelete} disabled={selectedBroadcasts.length === 0}>Apply</Button>
            )}
          </BulkActionContainer>
          <Table>
            <thead>
              <tr>
                <th>Select</th>
                <th>ID</th>
                <th>Broadcast ID</th>
                <th>Scheduled Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paginatedBroadcasts.map((broadcast) => (
                <tr key={broadcast.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedBroadcasts.includes(broadcast.id)}
                      onChange={() => handleCheckboxChange(broadcast.id)}
                    />
                  </td>
                  <td>{broadcast.id}</td>
                  <td>{broadcast.broadcast_id}</td>
                  <td>{new Date(broadcast.schedule_time).toLocaleString('en-US', { timeZone: 'Asia/Singapore' })}</td>
                  <td>
                    <button onClick={() => handleEdit(broadcast)}>Edit</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <PaginationContainer>
            <ReactPaginate
              previousLabel={"previous"}
              nextLabel={"next"}
              breakLabel={"..."}
              pageCount={Math.ceil(scheduledBroadcasts.length / ITEMS_PER_PAGE)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </PaginationContainer>
        </>
      )}
      <ScheduleModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditMode(false);
        }}
        onSchedule={handleSchedule}
        broadcastName={selectedBroadcast ? selectedBroadcast.name : 'selected broadcasts'}
      />
    </ContentContainer>
  );
};

export default MainContent;
